import { Container, Row, Col } from "react-bootstrap";
import logo from "../Assets/Images/logo.png";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import './Footer.css';

export const Footer = () => {
  function getCurrentYear() {
    return new Date().getFullYear();
  }

  const currentYear = getCurrentYear();

  return (
    <footer className="footer" id="footer">
      <Container>
        <Row className="footer-text align-items-center">
          <Col size={12} sm={6}>
            <div className="footer-img " style={{ textAlign: 'left' }}>
              <img style={{ width: "20%"}} src={logo} alt="Logo" />
            </div>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="footer-right">
              <div className="social-icon">
              <a href="https://www.instagram.com/">
                <FaInstagram />
                </a>
                <a href="https://wa.me/+919786853273">
                <FaWhatsapp />
                </a>
              </div>
              <p>Contact: +91 97868 53273</p>
              <p>Email: yeomansyield@outlook.com</p>
              <p>© {currentYear} Yeomans Yield. All Rights Reserved. </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
