import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaInstagram, FaWhatsapp } from "react-icons/fa"; 
import { HashLink } from "react-router-hash-link";
import { BrowserRouter as Router } from "react-router-dom";
import './NavBar.css';
import logo from "../Assets/Images/logo.png"

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
        <Navbar.Brand href="/">
            <img className="logo" src={logo} alt="Yeomans Yield" style={{ width: '120px' , paddingLeft:'20px' }}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#products"
                className={
                  activeLink === "products" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("products")}
              >
                Products
              </Nav.Link>
              <Nav.Link
                href="#aboutus"
                className={
                  activeLink === "aboutus"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("aboutus")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="#footer"
                className={
                  activeLink === "contact"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("contact")}
              >
                Contact
              </Nav.Link>
            
              
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.instagram.com/">
                <FaInstagram />
                </a>
                <a href="https://wa.me/+919786853273">
                <FaWhatsapp />
                </a>
              </div>
              <HashLink to="#footer">
                <button className="vvd">
                  <span>Reach Out</span>
                </button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
