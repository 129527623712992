import React from 'react';
import './Product.css';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import img1 from "../Assets/Images/product.png";
import img2 from "../Assets/Images/jaggery.png";



export const Products =()=>{
return(
  <section className='products' id='products'>
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <h1>Products</h1>
        </Col>
      </Row>
      <Row className='product-row'>
        <Col md={3} className="mb-4">
          <Card className="custom-card">
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Title>Jaggery Powder</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-4">
          <Card className="custom-card">
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title>Jaggery</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </section>
)
}