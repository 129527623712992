import logo from './logo.svg';
import './App.css';
import { NavBar } from './Components/NavBar';
import "bootstrap/dist/css/bootstrap.min.css";
import { Hero } from './Components/Hero';
import { Banner } from './Components/Banner';
import { Products } from './Components/Products.js';
import { About } from './Components/About';
import { Footer } from './Components/Footer.js';
function App() {
  return (
    <div className="App">
      <NavBar/>
      <Banner />
      {/* <Hero/> */}
      
      <About />
      <Products/>
      <Footer/>


    </div>
  );
}

export default App;
