import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import product from "../Assets/Images/product.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import "./Banner.css";
import TrackVisibility from "react-on-screen";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const period = 1500;

  

  return (
    <section className="banner" id="home">
      <Container className="mt-5">
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {() => (
                <div className="animate__animated animate__fadeIn">
                  <p>
                  Jaggery powder is a traditional, natural sweetener made from the sap of sugarcane. Unlike refined sugar, jaggery retains several essential nutrients because it is unrefined.
                  </p>
                  <p>
                  Jaggery powder is packed with minerals like iron, magnesium, potassium, and phosphorus, which are often stripped from refined sugar. It also contains trace amounts of vitamins.
                  </p>
              
                  <button onClick={() => (window.location.href = "#footer")} style={{ marginTop: '40px' }} >
                    Know more <ArrowRightCircle size={25} />
                  </button>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {() => (
                <div className="animate__animated animate__zoomIn">
                  <img src={product} alt="Product" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
