import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './About.css';
import logo from '../Assets/Images/logo.png';

export const About =()=>{
return(
    <section className='aboutus' id='aboutus'>
    <Container className="mt-5">
        <Row className='heading'>
        <h1>About Us</h1>

        </Row>
      <Row className="mb-4">
      <Col xs lg="2" className='image'>
        <img src={logo} style={{height:'100px'}}/>
          
        </Col>
        <Col className='about'>
          <h4 style={{ textAlign: 'left' }}>Welcome to Yeomans Yield!</h4>
          <p style={{ textAlign: 'left' }}>
          At Yeomans Yield, we are committed to producing the finest natural jaggery powder and jaggery, offering a healthier and more flavorful alternative to refined sugar. Founded with a passion for quality and sustainability, we pride ourselves on preserving traditional methods while integrating modern practices to ensure the highest standards of production.
          </p>
          <h4 style={{ textAlign: 'left' }}>Our Mission and Vision!</h4>
          <p style={{ textAlign: 'left' }}>
          At Yeomans Yield, our mission is to deliver wholesome and natural sweeteners that enhance the flavor of foods and beverages, contribute positively to health, and uphold the highest standards of quality and sustainability. Our vision is to inspire healthier dietary choices globally by leading the industry in producing natural, high-quality jaggery products while promoting sustainable agricultural practices.
          </p>
        
        </Col>
      </Row>
      
    </Container>
    </section>
)
}